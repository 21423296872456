import React from "react";
import "./Footer.css";

// import Icons Start

import { FaTrash } from "react-icons/fa";

// import Icons End

const Footer = () => {
  return (
    <div className="footer__container">
      <div className="footer__wrapper">
        <span>
            <button>Save</button>
            <button>Cancel</button>
        </span>
        <span>
            <FaTrash />
        </span>
      </div>
    </div>
  );
};

export default Footer;
