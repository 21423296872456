import React, { useState } from 'react';
import './App.css';
import cnx from './Context';

// Import Component Start

import Page from './Page';

// Import Component End

const App = () => {

  const [title , setTitle] = useState('');
  const [description , setDescription] = useState('');

  return (
    <div className='app'>
      <cnx.Provider value={{
        title,
        setTitle,
        description,
        setDescription,
      }}>
        <Page />
      </cnx.Provider>
    </div>
  );
}

export default App;