import React, { useState } from "react";
import "./PartTwo.css";
import cnx from "../../Context";
import { useContext } from "react";

// import Icons Start

import { AiOutlinePlus } from "react-icons/ai";
import { AiOutlineMinus } from "react-icons/ai";
import { AiOutlineAlignRight } from "react-icons/ai";
import { BiHide } from "react-icons/bi";
import { BiTimeFive } from "react-icons/bi";
import { AiTwotoneStar } from "react-icons/ai";

// import Icons End

const PartTwo = () => {
  const [titleFontSize, setTitleFontSize] = useState(16);
  const [descFontSize, setDescFontSize] = useState(16);

  let Context = useContext(cnx);

  return (
    <div className="partTwo__container">
      <div className="partTwo__wrapper">
        <p>Add Your Title And Text</p>
        <span className="partTwo__wrapper__one">
          <label htmlFor="idTitle">Title</label>
          <input
            type="text"
            placeholder="title"
            id="idTitle"
            value={Context.title}
            onChange={(e) => Context.setTitle(e.target.value)}
          />
        </span>
        <span className="partTwo__wrapper__two">
          <label htmlFor="idDesc">Description</label>
          <textarea
            name=""
            placeholder="Text"
            id="idDesc"
            rows={10}
            value={Context.description}
            onChange={(e) => Context.setDescription(e.target.value)}
          ></textarea>
        </span>
        <div className="partTwo__wrapper__three">
          <span>
            <label htmlFor="">Title Font Size</label>
            <div>
              <AiOutlinePlus
                onClick={() => setTitleFontSize((prev) => prev + 1)}
              />
              {titleFontSize}
              <AiOutlineMinus
                onClick={() => setTitleFontSize((prev) => prev - 1)}
              />
            </div>
          </span>
          <span>
            <label htmlFor="">Description Font Size</label>
            <div>
              <AiOutlinePlus
                onClick={() => setDescFontSize((prev) => prev + 1)}
              />
              {descFontSize}
              <AiOutlineMinus
                onClick={() => setDescFontSize((prev) => prev - 1)}
              />
            </div>
          </span>
        </div>
        <div className="partTwo__wrapper__four">
          <span>
            <label htmlFor="">Title Color</label>
            <div>
              <small>------ #</small>
              <p></p>
            </div>
          </span>
          <span>
            <label htmlFor="">Description Color</label>
            <div>
              <small>------ #</small>
              <p></p>
            </div>
          </span>
        </div>
        <div className="partTwo__wrapper__five">
          <span>
            <label htmlFor="">Align</label>
            <AiOutlineAlignRight />
          </span>
        </div>
        <div className="partTwo__wrapper__six">
          <span>
            <BiHide />
            <p>Hide</p>
          </span>
          <span>
            <BiTimeFive />
            <p>Schedule</p>
          </span>
          <span>
            <AiTwotoneStar />
            <p>POP (effect)</p>
          </span>
        </div>
      </div>
    </div>
  );
};

export default PartTwo;
