import React from 'react';
import './Page.css';

// import Component Start

import Preview from './Components/Preview/Preview';
import PartTwo from './Components/PartTwo/PartTwo';
import Footer from './Components/Footer/Footer';

// import Component End

const Page = () => {
    return (
        <div className='page__container'>
            <Preview />
            <PartTwo />
            <Footer />
        </div>
    );
}

export default Page;
