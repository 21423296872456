import React from "react";
import "./Preview.css";
import cnx from "../../Context";
import { useContext } from "react";

const Preview = () => {
  let Context = useContext(cnx);

  return (
    <div className="preview__container">
      <p>Preview title & description</p>
      <div>
        <h5>{Context.title === "" ? "Title" : Context.title }</h5>
        <small>{Context.description === "" ? "Description" : Context.description }</small>
      </div>
    </div>
  );
};

export default Preview;
